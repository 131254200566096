import React, { useCallback, useEffect, useRef, useState } from "react";
import { TextareaAutosize } from "@mui/material";
import { Button, IconButton, Switch } from "@bolt/components";
import { ChatGpcService } from "../../../services/ChatGpcService";
import { display } from "../../../utils/displayValues";
import { ChatMessage, ChatRequest } from "../../../types";
import "./ChatUiStyles.css";
import { AuthService } from "../../../services/AuthService";
import ChatMessageComponent from "../../ChatMessageComponent";
import MessageBot from "../../MessageBot";
import SkeletonTextBlock from "../../SkeletonTextBlock";
import ChatGPCLogo from "../../ChatGPCLogo";
import LogOutButton from "../../LogOutButton";
import useTheme from "../../../hooks/useTheme";
import DisclosureWarning from "../../DisclosureWarning";
import MobileSidebarToggleButton from "../../MobileSidebarToggleButton";
import ChatFooterDisclosure from "../../ChatFooterDisclosure";
import clsx from "clsx";
import {
  useSetUserInfoContext,
  useUserInfoContext,
} from "../../../contexts/UserInfoContext";

const ChatUi = () => {
  const setUserInfo = useSetUserInfoContext();
  const userInfo = useUserInfoContext()!;

  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [responding, setResponding] = useState<boolean>(false);
  const [userQuestion, setUserQuestion] = useState<string>("");
  const [chatID] = useState<string>(crypto.randomUUID());
  const msgEnd = useRef<HTMLDivElement>(null);
  const { theme, toggleTheme } = useTheme();
  const [expandedSidebarOnMobile, setExpandedSidebarOnMobile] =
    useState<boolean>(false);

  useEffect(() => {
    msgEnd?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [chatHistory]);

  const clearHistory = useCallback(async () => {
    await handleAbort();
    setChatHistory([]);
  }, [setChatHistory]);

  const toggleSidebar = useCallback(() => {
    setExpandedSidebarOnMobile((state) => !state);
  }, [setExpandedSidebarOnMobile]);

  const handleSend = async () => {
    sendMessage(userQuestion);
    setUserQuestion("");
  };

  const sendMessage = async (message: string) => {
    setResponding(true);
    setChatHistory((state) => [
      ...state,
      { content: message, role: "user", error: false },
    ]);

    let { accessToken } = userInfo;
    if (userInfo.expiresAt < Date.now()) {
      // TODO: the exceptions should be handled at refreshToken fetch
      try {
        const refreshResult = await AuthService.refreshToken(
          userInfo.refreshToken,
        );

        AuthService.setRefreshTokenCookie(refreshResult.refreshToken);

        accessToken = refreshResult.accessToken;
        setUserInfo(refreshResult);
      } catch (e: any) {
        console.error("Unable to refresh the token", e);
        setUserInfo(null);
      }
    }

    const request: ChatRequest = {
      chat_history: chatHistory,
      question: message,
      chat_id: chatID,
    };

    const result: ChatMessage = await ChatGpcService.askQuestion(
      request,
      accessToken,
      () => {
        setUserInfo(null);
      },
    );

    setChatHistory((state) => [...state, result]);
    setResponding(false);
  };

  const handleAbort = async () => {
    await ChatGpcService.abortQuestion();
    setResponding(false);
  };

  const handleRegenerateResponse = () => {
    const userQuestion =
      chatHistory
        .slice()
        .reverse()
        .find((message: ChatMessage) => message.role === "user")?.content || "";

    if (userQuestion) {
      sendMessage(userQuestion);
    } else {
      console.error("The user question from chatHistory is empty!");
    }
  };

  const onKeyDownTextarea = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      if (userQuestion !== "") {
        handleSend();
      }
      e.preventDefault();
    }
  };

  return (
    <div className="theme-container" data-theme={theme}>
      <div
        className={clsx(
          "chat-screen-container",
          expandedSidebarOnMobile && "sidebar-expanded",
        )}
      >
        <div className="sidebar">
          <ChatGPCLogo className="chat-img" />
          <div className="sidebarUpper">
            <div className="sidebarBarItem">
              <div className="sidebar-category">
                <Button
                  variant="outline"
                  size="lg"
                  data-testid="clear-history-btn"
                  onClick={clearHistory}
                >
                  Reset chat
                </Button>
              </div>
            </div>
          </div>
          <div className="theme-switch-container">
            <Switch
              data-testid="dark-mode-toggle"
              onClick={toggleTheme}
              label="dark mode"
              size="md"
              checked={theme === "dark"}
            />
          </div>
        </div>
        <div className="main">
          <div className="chat-header">
            <MobileSidebarToggleButton onClick={toggleSidebar} />
            <DisclosureWarning />
            <LogOutButton />
          </div>
          <div className="chats">
            <MessageBot>
              <p>{display.en.generalChatBotPrompt}</p>
            </MessageBot>
            {chatHistory.map((message: ChatMessage, index: number) => (
              <ChatMessageComponent
                key={index}
                theme={theme}
                message={message}
                handleRegenerateResponse={handleRegenerateResponse}
                isLatest={index === chatHistory.length - 1}
              />
            ))}
            {responding && (
              <MessageBot>
                <div style={{ width: "100%" }}>
                  <SkeletonTextBlock />
                </div>
              </MessageBot>
            )}
            <div ref={msgEnd} />
          </div>
          <div className="input-box-container">
            <div className="chat-text-area">
              <TextareaAutosize
                data-testid="chat-input"
                draggable={false}
                disabled={responding}
                placeholder="Ask me a question"
                onKeyDown={onKeyDownTextarea}
                onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                  setUserQuestion(e.currentTarget.value)
                }
                value={userQuestion}
              />
            </div>
            {responding ? (
              <IconButton
                ariaLabel="Stop generating response"
                onClick={handleAbort}
                className="abort-button"
                data-testid="abort-button"
                size="md"
                iconName="xmark-large"
                variant="outline"
                // Note: This is commented because it doesn't work as expected at current version of BOLT package
                // The override has been added at css file
                // intent="destructive"
              />
            ) : (
              <IconButton
                ariaLabel="Send"
                disabled={userQuestion === ""}
                className="send-button"
                data-testid="send-button"
                onClick={handleSend}
                size="md"
                iconName="chevron-right"
                variant="outline"
              />
            )}
          </div>
          <ChatFooterDisclosure />
        </div>
      </div>
    </div>
  );
};

export default ChatUi;

import React, { useEffect, useState } from "react";
import ChatUi from "./components/pages/ChatUi/ChatUi";
import LandingPage from "./components/pages/LandingPage/LandingPage";
import { TermsAndConditionsService } from "./services/TermsAndConditionsService";
import TermsAndConditions from "./components/pages/TermsAndConditions/TermsAndConditions";
import { AuthService } from "./services/AuthService";
import GuidanceInfoContent from "./components/pages/Info/GuidanceInfoContent";
import UserGuideInfoContent from "./components/pages/Info/UserGuideInfoContent";
import { InfoTemplate } from "./components/pages/Info/InfoTemplate";
import LoadingPage from "./components/pages/LoadingPage/LoadingPage";
import {
  useSetUserInfoContext,
  useUserInfoContext,
} from "./contexts/UserInfoContext";

function App() {
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(true);
  const userInfo = useUserInfoContext();
  const setUserInfo = useSetUserInfoContext();

  useEffect(() => {
    const codeMatch = window.location.href.match("[?#&]code=([^&]*)");
    const errorMatch = window.location.href.match(
      "User%20does%20not%20have%20appropriate%20group%20memberships",
    );

    const setState = async () => {
      setLoading(true);

      if (codeMatch?.[1]) {
        const authorize = async () => {
          const pingIdJson = await AuthService.getAccessToken(codeMatch[1]);
          AuthService.setRefreshTokenCookie(pingIdJson.refreshToken);

          setUserInfo(pingIdJson);
          setLoading(false);

          if (pingIdJson.accessToken) {
            const result = await TermsAndConditionsService.getTerms(
              pingIdJson.accessToken,
            );

            setTermsAccepted(result.accepted);
          }

          setLoading(false);
        };

        authorize();
      } else {
        const refresh_token = AuthService.getRefreshTokenCookie();

        if (refresh_token && refresh_token !== "undefined") {
          // so this is a little confusing. Instead of refreshing the token when the page is refreshed we are
          // just updating the user object, that way we don't have to worry about the state of tokens being
          // controlled by multiple tabs, the state of one window won't conflict with other opened sessions,
          // and it will save on initial load time
          setUserInfo({
            accessToken: "",
            refreshToken: refresh_token,
            expiresAt: 0,
            idToken: "",
          });
        }

        // Note: The loading at DEV mode is not displaying because useEffect is fired twice at dev mode.
        //       This line below is fired at second run and preventing displaying Loading page.
        setLoading(false);
      }

      window.history.replaceState({}, "", window.location.pathname);
    };

    if (errorMatch) {
      alert("user does not have access to the appropriate group");
      window.history.replaceState({}, "", window.location.pathname);
    } else {
      setState();
    }
  }, [setUserInfo, setLoading]);

  // ROUTING
  if (window.location.pathname === "/user-guide") {
    return (
      <InfoTemplate>
        <UserGuideInfoContent />
      </InfoTemplate>
    );
  }

  if (window.location.pathname === "/gen-ai-guidance") {
    return (
      <InfoTemplate>
        <GuidanceInfoContent />
      </InfoTemplate>
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  if (!userInfo) {
    return <LandingPage />;
  }

  if (!termsAccepted) {
    return <TermsAndConditions setTermsAccepted={setTermsAccepted} />;
  }

  return <ChatUi />;
}

export default App;

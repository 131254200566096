import { PingOneResponse, UserInfo } from "../types";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import {REFRESH_TOKEN_COOKIE_NAME, REFRESH_TOKEN_COOKIE_DEFAULT_EXPIRATION_DELAY_MS} from "../config/cookies";

export class AuthService {
  static async getAccessToken(code: string): Promise<UserInfo> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors" as RequestMode,
    };

    const apiResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/token?code=${code}&redirect_uri=${process.env.REACT_APP_BASE_URL}`,
      requestOptions,
    );

    const response: PingOneResponse = await apiResponse.json();

    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      expiresAt: response.expires_in * 1000 + Date.now(),
      idToken: response.id_token,
    };
  }

  static async refreshToken(refresh_token: string): Promise<UserInfo> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refresh_token}`,
      },
      mode: "cors" as RequestMode,
    };

    const apiResponse = await fetch(
      process.env.REACT_APP_BACKEND_URL + `/api/v1/refresh`,
      requestOptions,
    );

    const response: PingOneResponse = await apiResponse.json();

    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      expiresAt: response.expires_in * 1000 + Date.now(),
      idToken: response.id_token,
    };
  }

  static setRefreshTokenCookie(refresh_token: string): void {
    Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refresh_token, {
      expires: AuthService._getTokenExpirationDate(refresh_token) ?? new Date(Date.now() + REFRESH_TOKEN_COOKIE_DEFAULT_EXPIRATION_DELAY_MS),
    });
  }

  static getRefreshTokenCookie(): string | undefined {
    return Cookies.get(REFRESH_TOKEN_COOKIE_NAME);
  }

  static _getTokenExpirationDate(token: string): Date | undefined {
    try {
      const { exp } = jwtDecode(token);
      if (exp) {
        return new Date(exp * 1000);
      }
    } catch (error) {
      console.error('Failed to decode JWT:', error);
    }
  }
}
